import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import { optimizeInfo } from "../../models";

import {
  deliveryManifest,
  manifest,
  manifestLine,
  storageAddress,
} from "../../models/DeliveryManifest";

import { fetchoptimizeByJobIdAsync } from "./sliceOptimizeServices";
import {
  fetchAllManifestByDateAsync,
  fetchStorageAddressAsync,
  getRoutificDefaultSettings,
  updateRoutificDefaultSettings,
} from "./sliceServices";

const initialState = {
  deliveryManifest: {} as deliveryManifest,
  loading: false,
  loaded: false,
  storageAddress: {} as storageAddress,
  storageAddressLoading: false,
  storageAddressLoaded: false,

  manifestETA: [] as any,

  routificSettings: {},
  routificSettingsLoading: false,
  routificSettingsLoaded: false,
  optimizingGroups: [] as any,
};

const manifestSlice = createSlice({
  name: "manifest",
  initialState,
  reducers: {
    clearManifest: (state) => {
      state.deliveryManifest = {} as deliveryManifest;
      state.optimizingGroups = [] as any;
      state.loading = false;
      state.loaded = false;
    },
    moveRoute: (state, action: PayloadAction<any>) => {
      const { manifestTarget, position, manifestLineMoving } = action.payload;
      const addManifestLines = cloneDeep(manifestTarget.manifestLines);
      addManifestLines.splice(position - 1, 0, manifestLineMoving);
      addManifestLines.map((item: manifestLine, index: number) => {
        item.position = index;
        return item;
      });

      state.deliveryManifest.manifests = state.deliveryManifest.manifests.map(
        (manifest) => {
          if (manifest.id === manifestTarget.id) {
            manifest.manifestLines = addManifestLines;
          }
          return manifest;
        }
      );
    },
    replaceManifestsUpdatedETAs: (state, action: PayloadAction<any>) => {
      state.deliveryManifest.manifests = action.payload;
    },
    updateManifests: (state, action: PayloadAction<any>) => {
      const { manifests, unallocatedDocumentCount } = action.payload;
      if (manifests.length) {
        state.deliveryManifest.manifests = state.deliveryManifest.manifests.map(
          (manifest) =>
            manifests.find(
              (newManifest: manifest) =>
                newManifest && newManifest.id === manifest.id
            ) || manifest
        );
      }
      if (
        unallocatedDocumentCount &&
        state.deliveryManifest.unallocatedDocumentCount >= 0
      ) {
        state.deliveryManifest.unallocatedDocumentCount =
          state.deliveryManifest.unallocatedDocumentCount +
          unallocatedDocumentCount;
      }
    },
    updateETAIntoManifestItem: (state, action: PayloadAction<any>) => {
      state.deliveryManifest.manifests = state.deliveryManifest.manifests.map(
        (manifest: manifest) =>
          manifest.id === action.payload.id ? action.payload : manifest
      );
    },
    updateRoutificSettings: (state, action: PayloadAction<any>) => {
      state.routificSettings = {
        ...state.routificSettings,
        ...action.payload,
      };
    },
    addOptimizeGroups: (state, action: PayloadAction<any>) => {
      const { optimizeRequestData, items } = action.payload;
      state.optimizingGroups = [
        ...state.optimizingGroups,
        {
          optimizeInfo: items,
          estimateTimeInterval: optimizeRequestData.estimateTimeInterval,
          jobId: optimizeRequestData.jobId,
          manifests: state.deliveryManifest.manifests.filter((manifest) =>
            items.find((it: optimizeInfo) => it.manifestId === manifest.id)
          ),
        },
      ];

      state.deliveryManifest = {
        ...state.deliveryManifest,
        manifests: state.deliveryManifest.manifests.filter(
          (manifest) =>
            !items.find((it: optimizeInfo) => it.manifestId === manifest.id)
        ),
      };
    },
    addOptimizedGroupsInfo: (state, action: PayloadAction<any>) => {
      const { fetchOptimizeByJobIdData, jobId } = action.payload;
      state.optimizingGroups = state.optimizingGroups.map(
        (optimizingGroup: any) => {
          if (optimizingGroup.jobId === jobId) {
            const rs = {
              ...optimizingGroup,
              ...fetchOptimizeByJobIdData,
            };
            return rs;
          } else {
            return optimizingGroup;
          }
        }
      );
    },
    discardOptimizeGroup: (state, action: PayloadAction<any>) => {
      const { jobId, manifests } = action.payload;

      state.optimizingGroups = state.optimizingGroups.filter(
        (optimizingGroup: any) => optimizingGroup.jobId !== jobId
      );
      state.deliveryManifest = {
        ...state.deliveryManifest,
        manifests: manifests.concat(state.deliveryManifest.manifests),
      };
    },
    applyOptimizeGroup: (state, action: PayloadAction<any>) => {
      const { jobId, manifests, unallocatedDocumentCount } = action.payload;
      state.optimizingGroups = state.optimizingGroups.filter(
        (optimizingGroup: any) => optimizingGroup.jobId !== jobId
      );
      state.deliveryManifest = {
        ...state.deliveryManifest,
        manifests: manifests.concat(state.deliveryManifest.manifests),
      };
      state.deliveryManifest.unallocatedDocumentCount =
        (state.deliveryManifest.unallocatedDocumentCount || 0) +
        (unallocatedDocumentCount || 0);
    },
    moveAndApplyManifestLine: (state, action: PayloadAction<any>) => {
      const { manifestsUpdate, lineChangeList } = action.payload;

      const finalManifestsUpdate = manifestsUpdate.map((manifest: manifest) => {
        const lines = manifest.manifestLines.map((line: manifestLine) => {
          const lineChangeFound = lineChangeList.find(
            (lineChange: manifestLine) => line.id === lineChange.id
          );
          return {
            ...line,
            timestampManifest: lineChangeFound
              ? lineChangeFound.timestampManifest
              : line.timestampManifest,
          };
        });
        return {
          ...manifest,
          manifestLines: lines,
        };
      });

      state.deliveryManifest = {
        ...state.deliveryManifest,
        manifests: state.deliveryManifest.manifests.map((mnf) => {
          const mnfUpdate = finalManifestsUpdate.find(
            (mnfUd: manifest) => mnfUd.id === mnf.id
          );
          if (mnfUpdate) {
            return mnfUpdate;
          }
          return mnf;
        }),
      };
    },
    changeDriverIntoManifest: (state, action: PayloadAction<any>) => {
      const { manifestChangeDriver } = action.payload;

      state.deliveryManifest = {
        ...state.deliveryManifest,
        manifests: state.deliveryManifest.manifests.map((mnf) => {
          if (mnf.id === manifestChangeDriver.id) {
            return manifestChangeDriver;
          }
          return mnf;
        }),
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllManifestByDateAsync.pending, (state) => {
        state.loading = true;
        state.loaded = false;
        state.deliveryManifest = {} as deliveryManifest;
        state.optimizingGroups = [] as any;
      })
      .addCase(fetchAllManifestByDateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.deliveryManifest = action.payload;
      })
      .addCase(fetchStorageAddressAsync.pending, (state) => {
        state.storageAddressLoading = true;
      })
      .addCase(fetchStorageAddressAsync.fulfilled, (state, action) => {
        state.storageAddressLoading = false;
        state.storageAddressLoaded = true;
        state.storageAddress = action.payload;
      })

      .addCase(getRoutificDefaultSettings.pending, (state) => {
        state.routificSettingsLoading = true;
      })
      .addCase(getRoutificDefaultSettings.rejected, (state) => {
        state.routificSettingsLoading = false;
      })
      .addCase(getRoutificDefaultSettings.fulfilled, (state, action) => {
        state.routificSettingsLoading = false;
        state.routificSettingsLoaded = true;
        state.routificSettings = action.payload;
      })
      .addCase(updateRoutificDefaultSettings.pending, (state) => {
        state.routificSettingsLoading = true;
      })
      .addCase(updateRoutificDefaultSettings.rejected, (state) => {
        state.routificSettingsLoading = false;
      })
      .addCase(updateRoutificDefaultSettings.fulfilled, (state, action) => {
        state.routificSettingsLoading = false;
        state.routificSettings = action.payload;
      })

      .addCase(fetchoptimizeByJobIdAsync.pending, (state, action) => {
        console.log("fetchoptimizeByJobIdAsync.pending", state, action);
      })
      .addCase(fetchoptimizeByJobIdAsync.rejected, (state, action) => {
        console.log("fetchoptimizeByJobIdAsync.rejected", state, action);
      })
      .addCase(fetchoptimizeByJobIdAsync.fulfilled, (state, action) => {
        console.log("fetchoptimizeByJobIdAsync.fulfilled", state, action);
      });
  },
});

export const {
  moveRoute,
  clearManifest,
  updateManifests,
  updateETAIntoManifestItem,
  replaceManifestsUpdatedETAs,
  updateRoutificSettings,
  addOptimizeGroups,
  addOptimizedGroupsInfo,
  discardOptimizeGroup,
  applyOptimizeGroup,
  moveAndApplyManifestLine,
  changeDriverIntoManifest,
} = manifestSlice.actions;
export default manifestSlice;
